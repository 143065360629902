const guidance = [
    {
        path: '/guidance/:guidanceNo',
        name: 'guidance',
        component: () => import(/* webpackChunkName: "guidance" */'@/pages/guidance/index.vue'),
        meta: {
            title: '用药指导',
        },
    },

];

export default guidance;
