export default {
  // baseUrl: '//api-gateway.medbanks-test.com',
  // baseUrl: '//192.168.29.217:2784',
  baseUrl: 'https://api-gateway.medbanks-test.com',
  buryingPointUrl: 'https://point.medbanks-test.com',
  payUrl: 'http://pay.medbanks-test.cn/#/', // 支付
  payEnv: 'development',
  loginUrl: 'https://dtp.medbanks-test.com/usercenter',
  loginUrlPAK: 'https://dtp.medbanks-test.com/usercenter',
  txysOrigin: {
    '6af97d7deea1a1d2': 'TXYS',
  },
  source: '1100110110', // 思派产品内测(云药房)，恒瑞医生推广，defaultSource 从配置中取
  PAKSource: '1001110102', // 派安康source
  jian1BaoSource: '1100510101', //  思派健1宝（功能内测）
  arrSource: ['1100510101'], // 包邮的渠道
  qrCodeData: {
    url: 'https://test4sapioss-1253856364.cos.ap-beijing.myqcloud.com/jpeg/202103/WechatIMG74.jpeg',
    successTip: '您已支付成功',
    qrCodeTip: ['请长按二维码', '关注【思派云药房】公众号', '获取该订单的物流进度'],
  },
  expressFee: 0.01, // 邮费
  packageMailAmount: 0.03, // 满多少钱包邮
  postageConfig: { // key:渠道source
    1100510101: { // 思派健1宝
      expressFee: 0.01, // 邮费
      packageMailAmount: 168, // 满多少钱包邮
    },
    1100110110: { // 云药房
      expressFee: 0.01, // 邮费
      packageMailAmount: 168, // 满多少钱包邮
    },
    1301110101: { // 绿谷
      expressFee: 0.01, // 邮费
      packageMailAmount: 168, // 满多少钱包邮
    },
    1001110102: { // 派安康
      expressFee: 0.02, // 邮费
      packageMailAmount: 0.03, // 满多少钱包邮
    },
    1000310102: {
      expressFee: 0.02, // 邮费
      packageMailAmount: 0.03, // 满多少钱包邮
    },
  },
  lvguSource: '1301110101',

  activityProductCode: {
    NHS_DTP: {
      purchaseNum: 1,
      productCodeList: ['21375', '21403'],
    }, // 雀巢来源
  }, // 活动商品 key：不同活动标示 value：{num：限购数量，productCode:活动商品编码}
};
