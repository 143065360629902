import shoppingCart from './shopping-cart';
import drugUsers from './drug-users';
import home from './home';
import order from './order';
import drug from './drug';
import prescription from './prescription';
import pay from './pay';
import common from './common';
import cityService from './city-service';
import logout from './login';
import membershipCard from './membership-card';
import buryingPoint from './burying-point';

const apis = {
    ...shoppingCart,
    ...drugUsers,
    ...home,
    ...order,
    ...drug,
    ...prescription,
    ...pay,
    ...common,
    ...cityService,
    ...logout,
    ...membershipCard,
    ...buryingPoint,
};
export default apis;
