const membershipCard = [
    {
        path: '/mc_list',
        name: 'mc_list',
        component: () => import(/* webpackChunkName: "membership-card" */ '@/pages/membership-card'),
        meta: {
            title: '我的会员卡',
        },
    },
    {
        path: '/mc_detail',
        name: 'mc_detail',
        component: () => import(/* webpackChunkName: "membership-card" */ '@/pages/membership-card/detail'),
        meta: {
            title: '会员卡',
        },
    },
    {
        path: '/mc_bill_list',
        name: 'mc_bill_list',
        component: () => import(/* webpackChunkName: "membership-card" */ '@/pages/membership-card/bill_list'),
        meta: {
            title: '账单详情',
        },
    },
    {
        path: '/mc_bill_detail',
        name: 'mc_bill_detail',
        component: () => import(/* webpackChunkName: "membership-card" */ '@/pages/membership-card/bill_detail'),
        meta: {
            title: '交易记录详情',
        },
    },
    {
        path: '/mc_exchange',
        name: 'mc_exchange',
        component: () => import(/* webpackChunkName: "membership-card" */ '@/pages/membership-card/exchange'),
        meta: {
            title: '兑换',
        },
    },
    {
        path: '/mc_store',
        name: 'mc_store',
        component: () => import(/* webpackChunkName: "membership-card" */ '@/pages/membership-card/apply_store'),
        meta: {
            title: '药品商城',
        },
    },
];

export default membershipCard;
