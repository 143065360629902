// import api from '@/api';

export default {
    namespaced: true,
    state: {
        merchantObj: {
            name: '',
            drugstoreCode: '',
        },
    },
    getter: {},
    mutations: {
        // 清除药店信息
        clearMerchantInfo(state) {
            state.merchantObj = {
                name: '',
                drugstoreCode: '',
            };
        },

        // 添加药店
        addMerchantInfo(state, info) {
            state.merchantObj.name = info.name;
            state.merchantObj.drugstoreCode = info.drugstoreCode;
        },
    },
    actions: {},
};
