const funcUrlDel = (urlParams) => {
    let names = urlParams;
    if (typeof (names) === 'string') {
        names = [names];
    }
    const loca = window.location;
    const obj = {};
    const arr = loca.search.substr(1).split('&');
    // 获取参数转换为object
    for (let i = 0; i < arr.length; i += 1) {
        arr[i] = arr[i].split('=');
        // eslint-disable-next-line prefer-destructuring
        obj[arr[i][0]] = arr[i][1];
    }
    // 删除指定参数
    for (let v = 0; v < names.length; v += 1) {
        delete obj[names[v]];
    }
    // 重新拼接url
    // eslint-disable-next-line no-useless-escape
    const url = `${loca.origin + loca.pathname}?${JSON.stringify(obj).replace(/[\"\{\}]/g, '').replace(/\:/g, '=').replace(/\,/g, '&')}`;
    return url;
};

export default funcUrlDel;
