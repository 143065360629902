// import api from '@/api';

export default {
    namespaced: true,
    state: {
        userInfo: {},
        selected: {
            personName: '',
            personId: '',
            age: '',
            gender: '',
            phone: '',
        },
    },
    getter: {},
    mutations: {
        updateUserInfo(state, info) {
            state.userInfo = {
                ...state.userInfo,
                ...info,
            };
        },
        clearUserInfo(state) {
            state.userInfo = {};
        },
        updateUserSelected(state, info) {
            state.selected = {
                ...info,
            };
        },
        clearUserSelected(state) {
            state.selected = {
                personName: '',
                personId: '',
                age: '',
                gender: '',
                phone: '',
            };
        },
    },
    actions: {},
};
