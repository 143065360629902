import api from '@/api';

export default {
    namespaced: true,
    state: {
        list: [],
        // result: [], // 购物车选择的商品
    },
    getters: {
        mapDataToAttr: (state) => (data) => {
            const list = state.list.slice();
            const [filterOp] = list.filter((item) => item.goodsId === data.goodsId);
            // const productImages = data.productImages || data.productImageUrl;
            return {
                // max: data.availableNum,
                // goodsId: data.goodsId,
                merchantCode: filterOp ? filterOp.drugstoreCode : data.merchantCode,
                num: filterOp ? filterOp.count : 0,
                goodsId: data.goodsId,
                prescription: data.prescription,
                productName: data.productName,
                usualName: data.usualName,
                productPrice: data.productPrice || data.salePrice,
                // productImages: productImages && productImages.length ? productImages[0] : '',
                productImages: data.productImages || data.productImageUrl,
                availableNum: data.availableNum,
                memberPrice: data.memberPrice,
                specs: data.specs,
                wareCode: data.wareCode,
                productCode: data.productCode,
            };
        },
        mapResult(state) {
            return state.list.filter((item) => item.isSelected === 1).map((item) => item.goodsId);
        },
    },
    mutations: {
        // 购物车系列操作
        initCart(state, data) {
            state.list = data.slice();
        },
        updateCartCheckedState(state, { result, cb }) {
            state.list = state.list.map((item) => ({
                ...item,
                isSelected: result.includes(item.goodsId) ? 1 : 0,
            }));
            if (cb && typeof cb === 'function') {
                const newResult = state.list.filter((item) => item.isSelected === 1).map((item) => item.goodsId);
                cb(newResult);
            }
        },
        clearCart(state) {
            state.list = [];
        },
        updateCartAfterVerify(state, data) {
            const passVerifyGoodsId = {};
            data.forEach((item) => {
                passVerifyGoodsId[item.goodsId] = true;
            });
            const temp = [];
            state.list.forEach((item) => {
                if (passVerifyGoodsId[item.goodsId]) {
                    temp.push(item);
                }
            });
            state.list = temp.slice();
        },
    },
    actions: {
        // 初始化购物车清单
        async initCartAsync({ commit }, { merchantCode }) {
            const param = {
                merchantCode,
                customerId: window.localStorage.getItem('userId') || '99999',
            };
            await api.getShoppingCartInfo(param).then((res) => {
                const { detailList } = res.data;
                const data = detailList.map((item) => ({
                    goodsId: item.goodsId,
                    prescription: item.prescription,
                    drugstoreCode: item.drugstoreCode,
                    merchantCode: item.drugstoreCode,
                    productName: item.productName,
                    usualName: item.usualName,
                    productPrice: item.productPrice,
                    productImageUrl: item.productImages && item.productImages.length ? item.productImages[0] : '',
                    count: item.count,
                    isSelected: item.isSelected,
                    availableNum: item.availableNum,
                    memberPrice: item.memberPrice,
                    specs: item.specs,
                    productCode: item.productCode,

                }));
                commit('initCart', data);
            });
        },
        // 更新购物车清单
        updateCart({ state, commit }, item) {
            // 调用接口...
            const data = state.list;
            const {
                count, merchantCode, operateType, goodsId,
            } = item;
            const param = {
                customerId: window.localStorage.getItem('userId'),
                isSelected: count > 0 ? 1 : 0,
                merchantCode,
                operateType,
                count,
                goodsId,
            };

            if (param.operateType === 'ADD') {
                // const index = data.findIndex((option) => option.goodsId === param.goodsId);
                // if (index === -1) {
                //     data.push({
                //         ...item,
                //         isSelected: count > 0 ? 1 : 0,
                //     });
                // } else {
                //     data[index].count = param.count;
                // }

                const isExist = data.some((option) => option.goodsId === param.goodsId);
                if (isExist) {
                    data.forEach((option) => {
                        if (param.goodsId === option.goodsId) {
                            option.count = param.count;
                        }
                    });
                } else {
                    data.push({
                        ...item,
                        isSelected: count > 0 ? 1 : 0,
                    });
                }
            }

            if (param.operateType === 'SUBTRACT') {
                if (param.count) {
                    data.forEach((option) => {
                        if (param.goodsId === option.goodsId) {
                            option.count = param.count;
                        }
                    });
                } else {
                    const index = data.findIndex((option) => option.goodsId === param.goodsId);
                    data.splice(index, 1);
                }
            }

            commit('initCart', data);
            api.editShoppingCart(param);
        },
        // 清空购物车
        emptyCartAsync({ commit }, { merchantCode, cb }) {
            const param = {
                customerId: window.localStorage.getItem('userId'),
                operateType: 'EMPTY',
                merchantCode,
            };
            api.emptyCart(param).then((res) => {
                if (res.code === '0') {
                    commit('clearCart');
                    if (cb && typeof cb === 'function') {
                        cb();
                    }
                }
            });
        },
    },
};
