import Vue from 'vue';

// 多行省略指令
Vue.directive('ellipsis', {
    bind(ele, params) {
        const element = ele;
        const { value = 1 } = params;
        element.style.overflow = 'hidden';
        element.style.textOverflow = 'ellipsis';
        element.style.display = '-webkit-box';
        element.style.webkitBoxOrient = 'vertical';
        element.style.webkitLineClamp = value;
        // ele.style.userSelect = 'none';
    },
});
