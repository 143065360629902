const drug = [
  {
    path: '/drug-shy/:id/:merchantCode?',
    name: 'drug-shy',
    component: () => import(/* webpackChunkName: "drug" */ '@/pages/drug_sanheyuan'),
    meta: {
      title: '思派云药房',
    },
  },
];

export default drug;
