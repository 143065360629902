import fetch from '@/utils/fetch';
import clientApiRoutePrefix from './prefix';

const proxy = `${clientApiRoutePrefix}/mall`;
export default {
    getDrugAndPatient: (params) => fetch({
        url: `${proxy}/order/getDrugAndPatient`,
        method: 'post',
        params,
        independent: true,
    }),
    // 检查处方是否已经下过单
    checkIsAlreadyHasOrder: (params) => fetch({
        url: `${proxy}/order/prescription/order`,
        method: 'get',
        params,
        independent: true,
    }),
    getOpenId: (data) => fetch({
        url: '/api/pi/v2/client/mall/user/getClientAppUserToken',
        method: 'get',
        data,
    }),

};
