const checkout = [
    {
        path: '/checkout',
        name: 'checkout',
        component: () => import(/* webpackChunkName: "checkout" */ '@/pages/checkout'),
        meta: {
            title: '',
        },
    },
];

export default checkout;
