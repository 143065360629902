/*
 * @Author: zhangzc
 * @Email: zhicheng.zhang@medbanks.cn
 * @Date: 2020-03-27 13:56:13
 * @Last Modified by: zoe
 * @Last Modified time: 2022-02-28 15:58:58
 * @Description: 常量配置项
 * @Route: Route
 */
export default {
    channelCode: {
        PAK: 7, // 一级渠道:派安康
    },
};
