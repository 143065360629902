import fetch from '@/utils/fetch';
import clientApiRoutePrefix from './prefix';

const proxy = `${clientApiRoutePrefix}/mall`;
export default {
    uploadImg: (file) => {
        const form = new FormData();
        form.append('files', file.file);
        return fetch({
            url: `${proxy}/prescription/uploadimg`,
            params: form,
            method: 'post',
        });
    },
    getPrescriptionDetail: (params) => fetch({
        url: `${proxy}/prescription/detail`,
        params,
    }),
};
