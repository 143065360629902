import fetch from '@/utils/fetch';

const proxy = '/api/prescription-project/v1';
export default {
    getDrugUsersList: (params) => fetch({
        url: `${proxy}/person/findPersonByPage`,
        params: {
            ...params,
            bizCode: 'NEW_C',
        },
    }),
    addDrugUsers: (params) => fetch({
        url: `${proxy}/person?bizCode=NEW_C&bizCode2=NEW_C`,
        params: [{
            personName: params.name,
            credentialsNumber: params.IDcard,
            gender: params.gender,
            age: params.age,
            phone: params.phone,
            drugstoreCode: params.drugstoreCode,
            relation: 4, // 暂无页面配置项，默认为4（与本人关系，1：本人 2：家属 3：客户 4: 其他）
            credentialsType: 1,
        }],
        method: 'post',
        independent: true,
    }),
    deleteDrugUsers: (params) => fetch({
        url: `${proxy}/person/deletePersonByOperator?bizCode=NEW_C&personId=${params.personId}`,
        method: 'post',
    }),
    updateDrugUsers: (params) => fetch({
        // url: `${proxy}/person/update`,
        url: `${proxy}/person/user/updatePersonAndBind`,
        params: {
            personName: params.name,
            credentialsNumber: params.IDcard,
            gender: params.gender,
            age: params.age,
            phone: params.phone,
            personId: params.personId,
            bizCode: 'NEW_C',
            relation: 4, // 暂无页面配置项，默认为4（与本人关系，1：本人 2：家属 3：客户 4: 其他）
            credentialsType: 1,
        },
        method: 'post',
    }),
    // 暂时不用
    getDrugUsersDetail: (params) => fetch({
        url: `${proxy}/person/queryBatchIds`,
        params: {
            ...params,
            bizCode: 'NEW_C',
        },
    }),
};
