const city = [
    {
        path: '/city',
        name: 'city',
        component: () => import(/* webpackChunkName: "city" */ '@/pages/city-select/index.vue'),
        meta: {
            title: '选择城市',
        },
    },
];

export default city;
