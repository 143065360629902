import fetch from '@/utils/fetch';
import clientApiRoutePrefix from './prefix';

const prefix = `${clientApiRoutePrefix}/mall`;
export default {
  getGoodsDetail: (params) => fetch({
    url: `${prefix}/merchandise/goodsdetail`,
    params,
    // method: 'post',
  }),
  getStoreTags: (params) => fetch({
    url: `${prefix}/merchant/merchantandtag`,
    params,
    // method: 'post',
  }),
  // 三和缘专用接口
  getSHYStoreInfo: (params) => fetch({
    url: `${clientApiRoutePrefix}/shy/lists`,
    params,
  }),
  getStoreDetail: (params) => fetch({
    url: `${prefix}/merchandise/lists`,
    params,
    method: 'post',
  }),
};
