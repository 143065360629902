// eslint-disable-next-line import/no-extraneous-dependencies
import Qs from 'qs';
import Configs from '@/config';
import funcUrlDel from './funcUrlDel';

/**
 * 判断是否微信环境
 * @returns {Boolean}
 */
export function isWeixin() {
    const ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) && ua.match(/MicroMessenger/i)[0] === 'micromessenger') {
        return true;
    }
    return false;
}

/**
 * 判断终端系统类型
 * @returns {String}
 */
export function checkSystemType() {
    const u = navigator.userAgent;
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; // g
    const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
    if (isAndroid) {
        // 这个是安卓操作系统
        return 'android';
    }
    if (isIOS) {
        // 这个是ios操作系统
        return 'ios';
    }
    return '未知';
}

/**
 * 防抖处理
 * @param {*} wait
 */
export function debounce(wait) {
    let timer = null;
    return function (target, name, descriptor) {
        const fn = descriptor.value;

        descriptor.value = function (...rest) {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                fn.apply(this, ...rest);
            }, wait);
        };
    };
}

// 订单状态映射
export function checkOrderStatus(item) {
    const { orderMixStatus, logisticsStatus } = item;
    if ((orderMixStatus === 'DRUG_DONE' || orderMixStatus === 'DRUG_PAID') && (logisticsStatus === 'SIGNED')) {
        return '已完成';
    }
    if (orderMixStatus) {
        switch (orderMixStatus) {
        case 'DRUG_NEW':
            return '新建';
        case 'DRUG_CONFIRM':
            return '待付款';
        case 'DRUG_WAIT_PAY':
            return '待付款';
        case 'DRUG_PARTIAL_PAID':
            return '待付款';
        case 'DRUG_PAID':
            return '待发货';
        // case 'DRUG_DONE':
        //     return '已完成';
        case 'DRUG_REFUNDED':
            return '已退款';
        case 'DRUG_CANCEL':
            return '已取消';
        default:
            return '';
        }
    }
}
const showCancelBtnAndshowPayBtnOriginList = ['6', '8', '9', '14'];
// 6--思派云药房
// 8--腾讯医生
// 9--思派健1宝
// 14--派安康

// 取消按钮展示
// UN_PACKAGE:待确认, PACKAGE：待打包, UN_PICK_UP：待取货, DISPATCHING:配送中, UN_SIGN:待签收, SIGNED：已签收, CANCEL：已取消
export function showCancelBtn(item) {
    const { orderMixStatus, origin } = item;
    const { logisticsStatus } = item;
    if (orderMixStatus === 'DRUG_CANCEL' && logisticsStatus === 'CANCEL') {
        return false;
    }
    // 待支付 待打包，已收款已打包
    if ((orderMixStatus !== 'DRUG_REFUNDED') && (logisticsStatus === '' || logisticsStatus === 'PACKAGE' || logisticsStatus === 'UN_PACKAGE') && showCancelBtnAndshowPayBtnOriginList.includes(origin)) {
        return true;
    }
}

// 支付按钮展示
export function showPayBtn(item) {
    const { orderMixStatus, origin } = item;
    // const { logisticsStatus } = item;
    if ((orderMixStatus === 'DRUG_WAIT_PAY' || orderMixStatus === 'UNKNOWN') && showCancelBtnAndshowPayBtnOriginList.includes(origin)) {
        return true;
    }
}
// 物流按钮展示
export function showTrackBtn(item) {
    // const { orderMixStatus } = item;
    const { logisticsStatus } = item;
    if (logisticsStatus !== 'UN_PACKAGE' && logisticsStatus !== 'PACKAGE' && logisticsStatus !== '' && logisticsStatus !== 'CANCEL') {
        return true;
    }
}
/**
 * 时间格式化
 * 数据返回为：2020-05-14 16:00:00
 * 页面展示：2020.05.06 16:00:00
 */
export function dateFmt(date) {
    if (!date) return '';
    return date.replace(/-/g, '/');
}
/**
 * 获取url参数
 */
export function getUrlParser() {
    return Qs.parse(window.location.search.substring(1));
}

export function handleAfter401() {
    const originUrl = funcUrlDel(['userId', 'phone', 'token', 'openid', 'fromShare', 'shareCode']);

    const defaultSource = localStorage.getItem('defaultSource');

    let encodeUrl = defaultSource === '1100510101' ? originUrl : `${originUrl}`;

    const childEncodeUrl = encodeUrl.split('?');

    console.log('childEncodeUrl', childEncodeUrl);

    if (childEncodeUrl[1] === '') {
        encodeUrl = encodeUrl.substr(0, encodeUrl.length - 1);
    }

    if (sessionStorage.getItem('orderChannel') && sessionStorage.getItem('orderChannel') === 'jjga') {
        // 绿谷来源
        window.location.href = `${Configs.loginUrl}/authorize?sourceUrl=${encodeURIComponent(encodeUrl)}&source=${defaultSource}&headImg=true`;
    } else if (localStorage.getItem('channelCode') === 'PAK') {
        window.location.href = `${Configs.loginUrlPAK}/loginMiddlePage.html?sourceUrl=${encodeURIComponent(encodeUrl)}&source=${defaultSource}`;
    } else {
        // 非绿谷来源
        window.location.href = `${Configs.loginUrl}/loginMiddlePage.html?sourceUrl=${encodeURIComponent(encodeUrl)}&source=${defaultSource}`;
    }
}

// 计算邮费
export function getFreight(that) {
    const defaultSource = localStorage.getItem('defaultSource');
    let { freight } = that.detailData;
    const { isMember, memberPrice, productSum } = that;
    const { expressFee = 10, packageMailAmount = 168 } = Configs.postageConfig[defaultSource];
    if (Configs.arrSource.includes(defaultSource)) {
        freight = 0;
    } else if (isMember > 0) {
        if (memberPrice < packageMailAmount) {
            freight = expressFee;
        } else {
            freight = 0;
        }
    } else if (productSum < packageMailAmount) {
        freight = expressFee;
    } else {
        freight = 0;
    }

    return freight;
}
export const getDevice = function () {
    const u = navigator.userAgent;
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; // android终端
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
    if (isAndroid) {
        return 'android';
    }
    if (isiOS) {
        return 'ios';
    }
};
