/*
 * @Author: ajx
 * @Email: jianxin.an@medbanks.cn
 * @Date: 2020-11-10 15:53:00
 * @Last Modified by: zoe
 * @Last Modified time: 2021-05-25 19:00:15
 * @Description: Description
 * @Route: Route
 */
const home = [
    {
        path: '/home',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '@/pages/home'),
        meta: {
            title: '思派云药房',
            needSecondJump: true,
        },
    },
    {
        path: '/search-result/:type/:title?/:tid?',
        name: 'search-result',
        component: () => import(/* webpackChunkName: "search_result" */ '@/pages/home/searchResult'),
        meta: {
            title: '思派云药房',
        },
    },
    {
        path: '/nineOneResult',
        name: 'nineOneResult',
        component: () => import(/* webpackChunkName: "nineOneResult" */ '@/pages/home/nineOneResult.vue'),
        meta: {
            title: '九期一',
        },
    },
];

export default home;
