import fetch from '@/utils/fetch';
import clientApiRoutePrefix from './prefix';

export default {
    getMerchantList: (params) => fetch({
        url: `${clientApiRoutePrefix}/mall/merchandise/merchantgoods`,
        params,
        loading: true,
    }),
    getTagList: (params) => fetch({
        url: `${clientApiRoutePrefix}/mall/merchandise/taglist`,
        params,
    }),
    getSearchResult: (params) => fetch({
        url: `${clientApiRoutePrefix}/mall/merchandise/goodslistsearch`,
        params,
        method: 'get',
        loading: true,
    }),
};
