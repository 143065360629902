// import api from '@/api';

export default {
    namespaced: true,
    state: {
        invoiceInfo: {
            switchChecked: false,
            invoice: '',
        },
    },
    getter: {},
    mutations: {
        // 清除发票信息
        clearInvoiceInfo(state) {
            state.invoiceInfo = {
                switchChecked: '',
                invoice: '',

            };
        },

        // 添加发票信息
        addInvoiceInfo(state, info) {
            state.invoiceInfo = { ...info };
        },
    },
    actions: {},
};
