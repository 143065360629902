/*
 * @Author: ajx
 * @Email: jianxin.an@medbanks.cn
 * @Date: 2020-11-16 17:53:11
 * @Last Modified by: ajx
 * @Last Modified time: 2020-11-17 15:51:45
 * @Description: Description
 * @Route: Route
 */

const drugUsers = [
    {
        path: '/drug-users-info/:id',
        name: 'drug-users-info',
        component: () => import(/* webpackChunkName: "drugUsers" */ '@/pages/drug_users/info.vue'),
        meta: {
            title: '添加用药人',
        },
    },
    {
        path: '/drug-users-list',
        name: 'drug-users-list',
        component: () => import(/* webpackChunkName: "drugUsers" */ '@/pages/drug_users/list.vue'),
        meta: {
            title: '用药人',
        },
    },
];

export default drugUsers;
