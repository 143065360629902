import WxTool from '@/utils/wx-tool';
import api from '@/api';
import Configs from '@/config';

export default {
    namespaced: true,
    state: {
        cityInfo: {},
    },
    mutations: {
        updateCityInfo(state, info) {
            state.cityInfo = {
                ...info,
                cityName: info.cityName.indexOf('市') > -1 ? info.cityName.substr(0, info.cityName.length - 1) : info.cityName,
            };
        },
    },
    actions: {
        getCurrentCityInfo({ commit }, cb) {
            const wxtool = WxTool.getInstance();
            wxtool.getLocation((res) => {
                console.log('res;', res);
                const { latitude: lat, longitude: lng } = res;
                if (!lat || !lng) return;
                wxtool.getCurrentCity(lat, lng, async (result) => {
                    const { cityCode, cityName } = result;
                    let results;
                    const channelCode = localStorage.getItem('channelCode');
                    if (channelCode === 'PAK') { // 派安康来源
                        results = await api.getCitys({ saleChannel: Configs.channelCode[channelCode.toUpperCase()] });
                    } else {
                        results = await api.getCitys();
                    }
                    const allCitys = results.data || [];
                    const [current] = allCitys.filter((item) => item.standardCode === cityCode);
                    commit('updateCityInfo', {
                        cityCode,
                        cityName: current?.name || cityName,
                        cityId: current?.cityId,
                        provincesId: current?.provincesId,
                    });
                    if (cb && typeof cb === 'function') {
                        cb();
                    }
                });
            });
        },
    },
};
