// import api from '@/api';

export default {
    namespaced: true,
    state: {
        consigneeInfo: {
            name: '',
            tel: '',
            address: '',
            detailAddress: '',
            axis: {
                latitudes: '',
                longitudes: '',
            },
        },
        QQAddress: {},
    },
    getter: {},
    mutations: {
        // 清除收获地址信息
        clearConsigneeInfo(state) {
            state.consigneeInfo = {
                name: '',
                tel: '',
                address: '',
                detailAddress: '',
                axis: {
                    latitudes: '',
                    longitudes: '',
                },
            };
            state.QQAddress = {};
        },

        // 添加收货地址信息
        updateConsigneeInfo(state, obj) {
            console.log(obj, '----obj');
            state.consigneeInfo = { ...obj };
            console.log(state, '----state');
        },

        // 添加收货人地址
        addAddressInfo(state, info) {
            state.QQAddress = { ...info };
        },
    },
    actions: {},
};
