/*
 * @Author: mengying.yao
 * @Email: mengying.yao@medbanks.cn
 * @Date: 2019-11-14 11:35:15
 * @Last Modified by: zoe
 * @Last Modified time: 2022-04-08 17:11:42
 * @Description: Description
 * @Route: Route
 */
import base from './base';
import dev from './config.dev';
import prod from './config.prod';
import test from './config.test';

let config = {};
if (process.env.NODE_ENV === 'production' && !process.env.VUE_APP_ISUAT) {
  config = prod;
} else if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_ISUAT) {
  config = test;
} else {
  config = dev;
}
export default Object.assign(base, config);
