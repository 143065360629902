import fetch from '@/utils/fetch';
import clientApiRoutePrefix from './prefix';

const proxy = `${clientApiRoutePrefix}`;

export default {
    getOrderList: (params, fn) => fetch({
        url: `${proxy}/mall/order/list`,
        params,
        cancelToken: fn,
    }),
    getOrderDetail: (params) => fetch({
        url: `${proxy}/mall/order/detail`,
        params,
    }),
    getOrderTrack: (params) => fetch({
        url: `${proxy}/mall/order/track`,
        params,
        method: 'post',
    }),
    getOrderCancel: (params) => fetch({
        url: `${proxy}/mall/order/cancel`,
        params,
        method: 'post',
        independent: true,
        loading: true,
    }),
    // 支付接口
    getCreatePay: (params) => fetch({
        url: `${proxy}/mall/order/createPay`,
        params: {
            ...params,
            sourceWx: localStorage.getItem('defaultSource'),
        },
        method: 'post',
        independent: true,
    }),
    // 创建订单接口
    postCreateOrder: (params) => fetch({
        url: `${proxy}/mall/order/create`,
        params: {
            ...params,
            sourceWx: localStorage.getItem('defaultSource'),
        },
        method: 'post',
        independent: true,
        loading: true,
    }),
    postCouponList: (params) => fetch({
        url: `${proxy}/mall/card/userAvailable`,
        params: {
            ...params,
            userId: window.localStorage.getItem('userId'),
            isMember: sessionStorage.getItem('isMember') ? sessionStorage.getItem('isMember') : 0,
        },
        method: 'post',
        loading: true,
    }),

    selectCouponItem: (params) => fetch({
        url: `${proxy}/mall/card/cardDiscount`,
        params: {
            ...params,
            userId: window.localStorage.getItem('userId'),
            isMember: sessionStorage.getItem('isMember') ? sessionStorage.getItem('isMember') : 0,
        },
        method: 'post',
        loading: true,
    }),
    // 查询签收订单详情
    getQueryByDetail: (params) => fetch({
        url: `${proxy}/comment/detail`,
        params,
    }),
    // 查询订单是否签收
    getQueryByOrderNo: (params) => fetch({
        url: `${proxy}/comment/query-by-orderno`,
        params,
        method: 'post',
    }),
    // 评分
    addScoreInfo: (params) => fetch({
        url: `${proxy}/comment/add`,
        params,
        method: 'post',
    }),
    // 用药指导详情
    getGuidanceByDetail: (params) => fetch({
        url: `${proxy}/comment/guidance`,
        params,
    }),
};
