import Vue from 'vue';
import VueRouter from 'vue-router';
import { getUrlParser } from '@/utils';
import Configs from '@/config';
import home from './home';
import store from './store';
import storeSanheyuan from './store_sanheyuan';
import city from './city';
import checkout from './checkout';
import order from './order';
import prescription from './prescription';
import drug from './drug';
import drugSanheyuan from './drug_sanheyuan';
import drugUsers from './drug_users';
import address from './add_address';
import membershipCard from './membership-card';
import signOrderDetail from './sign_order_detail';
import guidance from './guidance';
import thirdPartyPay from './third_party_pay';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "index" */ '@/pages/index'),
    redirect: '/home',
  },
  ...home,
  ...store,
  ...storeSanheyuan,
  ...city,
  ...order,
  ...checkout,
  ...prescription,
  ...drug,
  ...drugSanheyuan,
  ...drugUsers,
  ...address,
  ...membershipCard,
  ...signOrderDetail,
  ...guidance,
  ...thirdPartyPay,
];

const router = new VueRouter({
  mode: 'history',
  routes,
  base: '/pysc/',
});

router.beforeEach((to, from, next) => {
  /**
     * 单个公众号只存defaultSource、token、userId、phone
     * 互联网医院还需要存prescriptionId
     */
  const {
    token, userId, phone, prescriptionId, defaultSource, channelEncode, isMember, shareCode, orderChannel, jumpMpPay, channelCode, secondChannelCode,
  } = to.query;

  if (defaultSource) {
    localStorage.setItem('defaultSource', defaultSource);
  }

  // 如果页面来自分享，不缓存用户信息 RegExp.$1 => '1'
  // eslint-disable-next-line no-invalid-regexp
  const regExp = new RegExp('[?|&]fromShare=([^&]*)', 'g');
  if (regExp.test(window.location.href)) {
    document.title = to.meta.title;
    localStorage.removeItem('authentication', token);
    localStorage.removeItem('userId', token);
    localStorage.removeItem('phone', token);
    localStorage.removeItem('shareCode', shareCode);
    next();
    return;
  }

  if (orderChannel) {
    sessionStorage.setItem('orderChannel', orderChannel);
  }

  // 标识订单来源: 目前只有腾讯医生来源的订单有此字段, 其他来源的订单没有这个字段
  if (channelEncode && channelEncode !== 'undefined') {
    localStorage.setItem('channelEncode', channelEncode);
  } else {
    localStorage.removeItem('channelEncode');
  }

  if (channelCode) { // 一级渠道
    localStorage.setItem('channelCode', channelCode);
  }

  if (secondChannelCode) { // 二级渠道
    localStorage.setItem('secondChannelCode', secondChannelCode);
  }

  if (token && token !== 'undefined') {
    localStorage.setItem('authentication', token);
  }
  if (userId && userId !== 'undefined') {
    console.log('saveUserId', userId, '111');
    localStorage.setItem('userId', userId);
  }
  if (phone && phone !== 'undefined') {
    // 若登陆信息和互联网医院登陆信息不一致
    if (sessionStorage.sourceParam) {
      const hlwSourcePhone = JSON.parse(sessionStorage.sourceParam).phone;
      if (hlwSourcePhone && hlwSourcePhone !== phone) {
        router.push({ name: 'home' });
      }
    }
    localStorage.setItem('phone', phone);
  }
  // 健康宝会员价-广州
  if (isMember && isMember !== 'undefined') {
    sessionStorage.setItem('isMember', isMember);
  }
  // if (prescriptionId) { // 互联网医院，下处方单购药流程
  //     sessionStorage.sourceParam = JSON.stringify(getUrlParser());
  //     localStorage.setItem('defaultSource', getUrlParser().source);
  //     if (getUrlParser().userId) {
  //         localStorage.setItem('userId', getUrlParser().userId);
  //     }

  //     if (jumpMpPay) {
  //         localStorage.setItem('jumpMpPay', jumpMpPay);
  //     }

  //     document.title = to.meta.title;
  //     next();
  // }
  if (jumpMpPay) {
    localStorage.setItem('jumpMpPay', jumpMpPay);
  }
  // 恒瑞医生推广，特殊处理defaultSource
  const shareDoctorCode = window.localStorage.getItem('shareCode');
  if (shareCode && shareCode !== 'undefined' && (!shareDoctorCode)) {
    localStorage.setItem('shareCode', shareCode);
    localStorage.setItem('defaultSource', Configs.source);
  }

  document.title = to.meta.title;
  next();
});

// router.afterEach((to) => {
//   console.log('after Each', to);
  // const url = `${window.location.origin}/pysc${to.fullPath}`;
  // const u = navigator.userAgent;
  // if (u.indexOf('iPhone') > -1 || u.indexOf('iOS') > -1) {
  //   console.log('ios', window.configUrl);
  //   if (window.configUrl === '' || window.configUrl === undefined) {
  //     console.log('set ios');
  //     window.configUrl = url;
  //   }
  // } else {
  //   window.configUrl = url;
  // }
// });

export default router;
