export default {
  baseUrl: 'https://api-gateway.medbanks.cn',
  buryingPointUrl: 'https://point.medbanks.cn',
  payUrl: 'https://m.spcare.cn/pay/#/', // 支付
  payEnv: 'production',
  loginUrl: '//m.sipaiyaofang.com/usercenter',
  loginUrlPAK: '//m.sipaiyiyuan.com/usercenter',
  txysOrigin: {
    bccfd88bad5d43b2: 'TXYS',
  },
  qrCodeData: {
    url: 'https://insurance-1253856364.cos.ap-beijing.myqcloud.com/erp_client_release/qrcode_default.jpg',
    successTip: '您已支付成功',
    qrCodeTip: ['请长按二维码', '关注【思派云药房】公众号', '获取该订单的物流进度'],
  },
  source: '1000510101', // 思派云药房，恒瑞医生推广，defaultSource 从配置中取
  PAKSource: '1001110102', // 派安康source，需要产品配
  jian1BaoSource: '1300310101', // 思派健1宝（生产）
  arrSource: ['1300310101'], // 包邮的渠道
  expressFee: 10, // 邮费
  packageMailAmount: 168, // 满多少钱包邮
  postageConfig: { // key:渠道source
    1300310101: { // 思派健1宝
      expressFee: 10, // 邮费
      packageMailAmount: 168, // 满多少钱包邮
    },
    1000510101: { // 云药房
      expressFee: 10, // 邮费
      packageMailAmount: 168, // 满多少钱包邮
    },
    1301110101: { // 绿谷
      expressFee: 10, // 邮费
      packageMailAmount: 168, // 满多少钱包邮
    },
    1001110102: { // 派安康 需要产品配
      expressFee: 0.01, // 邮费
      packageMailAmount: 0.03, // 满多少钱包邮
    },
    1000310102: {
      expressFee: 0.02, // 邮费
      packageMailAmount: 0.03, // 满多少钱包邮
    },
  },
  lvguSource: '1301110101',
  activityProductCode: {
    NHS_DTP: {
      purchaseNum: 1,
      productCodeList: ['27514', '27515'],
    },
  }, // 活动商品 key：不同活动标示 value：{num：限购数量，productCode:活动商品编码} 待产品配置
};
