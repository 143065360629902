import fetch from '@/utils/fetch';
import clientApiRoutePrefix from './prefix';

const proxy = `${clientApiRoutePrefix}/mall`;

export default {
    // 会员卡列表
    getMCList: (params) => fetch({
        url: `${proxy}/card/list`,
        params,
    }),
    // 会员卡详情
    getMCDetail: (params) => fetch({
        url: `${proxy}/card/find`,
        params,
    }),
    // 账单列表
    getMCBillList: (params) => fetch({
        url: `${proxy}/card/records`,
        params,
    }),
    // 账单详情
    getMCBillDetail: (params) => fetch({
        url: `${proxy}/card/record/find`,
        params,
    }),
    // 会员卡激活
    exchangeMC: (params) => fetch({
        url: `${proxy}/card/activate`,
        params: {
            ...params,
            userId: window.localStorage.getItem('userId'),
        },
        method: 'post',
    }),
    // 会员卡激活校验
    checkMC: (params) => fetch({
        url: `${proxy}/card/check`,
        params: {
            ...params,
            userId: window.localStorage.getItem('userId'),
        },
        method: 'post',
    }),
    // 会员卡适用门店和商品
    getMCApplyStore: (params) => fetch({
        url: `${proxy}/card/avalible/merchant`,
        params,
    }),
    // 获取微信用户信息
    getWXUserInfo: () => fetch({
        url: '/api/usercenter/userinfo/detail',
        params: {
            source: localStorage.getItem('defaultSource'),
            userId: localStorage.getItem('userId'),
        },
        method: 'post',
    }),
};
