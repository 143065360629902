const thirdPartyPay = [
    {
        path: '/allinpay',
        name: 'allinpay',
        component: () => import(/* webpackChunkName: "allinpay" */ '@/pages/third_party_pay/allinpay.vue'),
        meta: {
            title: '支付结果',
        },
    },
];

export default thirdPartyPay;
