const address = [
    {
        path: '/add-address',
        name: 'add-address',
        component: () => import(/* webpackChunkName: "add_address" */ '@/pages/address/add_address'),
        meta: {
            title: '添加新地址',
        },
    },
    {
        path: '/search-address',
        name: 'search-address',
        component: () => import(/* webpackChunkName: "search_address" */ '@/pages/address/search_address'),
        meta: {
            title: '所在地区',
        },
    },
];

export default address;
