import fetch from '@/utils/fetch';
import clientApiRoutePrefix from './prefix';

const prefix = `${clientApiRoutePrefix}/mall`;
export default {
    editShoppingCart: (params) => fetch({
        url: `${prefix}/trades/editShoppingCart`,
        method: 'post',
        params,
    }),
    getShoppingCartInfo: (params) => fetch({
        url: `${prefix}/trades/getShoppingCartInfo`,
        params,
    }),
    emptyCart: (params) => fetch({
        url: `${prefix}/trades/emptyCart`,
        params,
    }),
    orderProductVerify: (params) => fetch({
        url: `${prefix}/trades/orderProductVerify`,
        params,
        method: 'post',
    }),
};
