export default {
    namespaced: true,
    state: {
        // 用户是否修改了会员卡选择方案
        isCustom: false,
        // 用户自定点选的优惠卡
        cardIds: [],
        couponCellStatus: undefined,
        defaultDeductAmount: 0,
        relation: [],
    },
    getter: {},
    mutations: {
        // 清除发票信息
        toggleIsCustom(state, custom) {
            state.isCustom = custom;
        },

        // 添加发票信息
        setSelectCardIds(state, info) {
            state.cardIds = info;
        },

        // 初始化状态
        clearCustomCouponSelect(state) {
            state.cardIds = [];
            state.isCustom = false;
            state.couponCellStatus = undefined;
            state.defaultDeductAmount = 0;
            state.relation = [];
        },

        // 切换会员卡cell的状态
        toggleCouponCellStatus(state, status) {
            state.couponCellStatus = status;
        },

        setDefaultDeductAmount(state, amount) {
            state.defaultDeductAmount = amount;
        },

        setRelation(state, relation) {
            state.relation = relation;
        },
    },
    actions: {},
};
