// import api from '@/api';

export default {
    namespaced: true,
    state: {
        drugUersSelected: {
            personName: '',
            personId: '',
            age: '',
            gender: '',
            phone: '',
        },
        currentTab: 1,
        diseases: [],
        diseaseInvestigation: {
            taboo: '', // 禁忌
            allergy: '', // 过敏反应
            adverseReaction: '', // 不良反应
        },
        eletronicPrescriptions: [], // 电子处方
        availablePrescriptions: [], // 已有处方
        // fileList: [],
        // fileList: [
        //     {
        //         url: 'https://img.yzcdn.cn/vant/leaf.jpg',
        //         status: 'uploading',
        //         message: '上传中...',
        //     },
        //     {
        //         url: 'https://img.yzcdn.cn/vant/tree.jpg',
        //         status: 'failed',
        //         message: '上传失败',
        //     },
        // ],
        notClearMark: false, // 是否清除state的标记
    },
    getter: {},
    mutations: {
        // 清除处方信息
        clearPrescriptionInfo(state) {
            state.drugUersSelected = {
                name: '',
                personId: '',
            };
            state.currentTab = 1;
            state.diseases = [];
            state.diseaseInvestigation = {
                taboo: '', // 禁忌
                allergy: '', // 过敏反应
                adverseReaction: '', // 不良反应
            };
            state.eletronicPrescriptions = [];
            state.availablePrescriptions = [];
            state.notClearMark = false;
        },
        // 更新处方信息
        updatePrescriptionInfo(state, obj) {
            Object.assign(state, { ...state, ...obj });
        },
        // 选择用药人
        updateDrugUser(state, info) {
            state.drugUersSelected = { ...info };
        },
        saveCurrentTabState(state, status) {
            state.currentTab = status;
        },
    },
    actions: {},
};
