import wx from 'weixin-js-sdk';
import { Toast } from 'vant';
import api from '@/api';
import QQMapWX from '@/assets/tencent_map/qqmap-wx-jssdk';
import Configs from '@/config';

const qqMapObj = new QQMapWX({
  key: 'CMBBZ-JHIR4-YTKUD-DBOHZ-NQ5X6-2IFWK', // 自己的key秘钥 http://lbs.qq.com/console/mykey.html 在这个网址申请
});
// const { source } = Configs;

class WxTool {
  constructor() {
    console.log('WxTool init');
  }

  static getInstance() {
    if (!WxTool.instance) {
      WxTool.instance = new WxTool();
    }
    return WxTool.instance;
  }

  static async initConfig() {
    console.log('initConfig', {
      url: window.configUrl,
      source: localStorage.getItem('defaultSource'),
    });
    const defaultSource = localStorage.getItem('defaultSource');
    const sourceList = ['1000310102', '1001110102']; // 小程序来源的source不能调起API，需要借用一下公众号的source
    await api.getSignatureInfo({
      url: window.configUrl,
      source: sourceList.includes(defaultSource) || defaultSource === null ? Configs.source : defaultSource,
    }).then((res) => {
      const {
        appId, timestamp, nonceStr, signature,
      } = res.data;

      wx.config({
        // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId, // 必填，公众号的唯一标识
        timestamp, // 必填，生成签名的时间戳
        nonceStr, // 必填，生成签名的随机串
        signature, // 必填，签名
        jsApiList: [
          'chooseImage',
          'uploadImage',
          'getLocalImgData',
          'getLocation',
          'scanQRCode',
          'chooseLocation',
          'updateAppMessageShareData',
          'updateTimelineShareData',
        ], // 必填，需要使用的JS接口列表
      });
    });
  }

  async getLocation(successCb) {
    console.log('getLocation');
    await WxTool.initConfig();

    const timer = setTimeout(() => {
      Toast('定位失败，请手动选择城市');
      if (successCb && typeof successCb === 'function') {
        successCb({
          latitude: '',
          longitude: '',
        });
      }
    }, 5 * 1000);

    await wx.ready(() => {
      wx.checkJsApi({
        jsApiList: ['getLocation'],
        success(res) {
          if (!res.checkResult.getLocation) {
            Toast('未开启定位权限');
          }
        },
        fail(err) {
          console.log(err);
        },
      });

      wx.getLocation({
        type: 'wgs84',
        success(res) {
          clearTimeout(timer);
          if (successCb && typeof successCb === 'function') {
            successCb(res);
          }
        },
        fail(err) {
          console.log(err, '----- getLocation error -----');
          // Toast('定位失败，请手动选择城市');
          if (successCb && typeof successCb === 'function') {
            successCb({
              latitude: '',
              longitude: '',
            });
          }
        },
        cancel() {
          console.log('cancel');
          clearTimeout(timer);
        },
        complete() {
          console.log('complete');
          clearTimeout(timer);
        },
      });
      // wx.error((err) => {
      //     // Toast('接口调用失败');
      //     console.log(err, '----- getLocation error -----');
      // });
    });
  }

  // 定位当前城市信息
  getCurrentCity(latitude, longitude, callBack) {
    qqMapObj.reverseGeocoder({
      latitude,
      longitude,
      // latitude: 34.691796, longitude: 112.442531,
      success: (res) => {
        const {
          city_code: cityCode,
          nation_code: nationCode,
        } = res.result.ad_info;
        const cityName = res.result.ad_info.city.replace('市', '');
        const regExp = new RegExp(`(^${nationCode})(\\d*)`);
        cityCode.match(regExp);
        if (callBack && typeof callBack === 'function') {
          callBack({
            cityCode: RegExp.$2,
            cityName,
          });
        }
      },
      fail: (fail) => {
        console.log('fail', fail);
      },
    });
  }

  async onShare(param = {}) {
    await WxTool.initConfig();
    let link = window.location.href.indexOf('?') === -1
      ? `${window.location.href}?fromShare=1`
      : `${window.location.href}&fromShare=1`;
    const regExp = new RegExp('[?|&]defaultSource=([^&]*)', 'g');
    if (!regExp.test(link)) {
      link += `&defaultSource=${localStorage.getItem('defaultSource')}`;
    }
    if (param.shareId) {
      link += '&shareForPoint=1';
    }
    const data = {
      title: param.title || '思派云药房商城',
      desc: param.desc || '',
      link,
      imgUrl: param.imgUrl || 'https://test4sapioss-1253856364.cos.ap-beijing.myqcloud.com/png/202012/store.png',
    };

    wx.ready(() => {
      wx.updateAppMessageShareData(data);
      wx.updateTimelineShareData(data);

      // wx.checkJsApi({
      //     jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'],
      //     success(res) {
      //         if (!res.checkResult.updateAppMessageShareData) {
      //             Toast('未开启分享给朋友的权限');
      //             return;
      //         }
      //         if (!res.checkResult.updateTimelineShareData) {
      //             Toast('未开启分享到朋友圈的权限');
      //             return;
      //         }
      //         if (res.checkResult.updateAppMessageShareData) {
      //             wx.updateAppMessageShareData(data);
      //         }
      //         if (res.checkResult.updateTimelineShareData) {
      //             wx.updateTimelineShareData(data);
      //         }
      //     },
      // });
      // wx.error(() => {
      //     Toast('wx_api调用失败');
      // });
      wx.error((err) => {
        console.log(err, '----- share error -----');
      });
    });
  }
}

export default WxTool;
