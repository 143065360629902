/*
 * @Author: ajx
 * @Email: jianxin.an@medbanks.cn
 * @Date: 2020-11-10 15:53:00
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2021-04-01 15:42:01
 * @Description: Description
 * @Route: Route
 */
const store = [
    {
        path: '/store/:merchantCode',
        name: 'store',
        component: () => import(/* webpackChunkName: "store" */ '@/pages/store'),
        meta: {
            title: '',
        },
    },
    {
        path: '/search-store',
        name: 'search-store',
        component: () => import(/* webpackChunkName: "store_search" */ '@/pages/store/search'),
        meta: {
            title: '',
        },
    },
    {
        path: '/pay',
        name: 'pay',
        component: () => import(/* webpackChunkName: "pay" */ '@/pages/store/pay'),
        meta: {
            title: '支付',
        },
    },
    {
        path: '/sourceToPay',
        name: 'sourceToPay',
        component: () => import(/* webpackChunkName: "source_to_pay" */ '@/pages/store/sourceToPay'),
        meta: {
            title: '支付',
        },
    },

];

export default store;
