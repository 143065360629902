const drug = [
    {
        path: '/drug/:id/:merchantCode?',
        name: 'drug',
        component: () => import(/* webpackChunkName: "drug" */ '@/pages/drug'),
        meta: {
            title: '思派云药房',
        },
    },
];

export default drug;
