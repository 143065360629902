import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import shoppingCart from './shopping-cart';
import drugUsers from './drug-users';
import prescription from './prescription';
import consignee from './consignee';
import merchantInfo from './merchant-Info';
import pay from './pay';
import city from './city';
import coupon from './coupon';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        shoppingCart,
        drugUsers,
        prescription,
        consignee,
        merchantInfo,
        pay,
        city,
        coupon,
        // moduleShoppingCart,
        // moduleDrugUsers,
    },
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
});
// store.registerModule('shoppingCart', moduleShoppingCart);
// store.registerModule('drugUsers', moduleDrugUsers);

export default store;
