import fetch from '@/utils/fetch';
import config from '@/config';

export default {
  sendBuriedPoint: (params) => fetch({
    url: `${config.buryingPointUrl}/api/pi-point-service/point/collect`,
    method: 'post',
    params,
  }),
  getOpenId: (params) => fetch({
    url: '/api/usercenter/userinfo/platform/detail',
    method: 'post',
    params,
  }),
};
