const order = [
    {
        path: '/order',
        name: 'order',
        component: () => import(/* webpackChunkName: "order" */ '@/pages/order/list.vue'),
        meta: {
            title: '我的订单',
            // keepAlive: true,
            // isBack: false,
        },
    },
    {
        path: '/detail/:mainOrderNo',
        name: 'detail',
        component: () => import(/* webpackChunkName: "detail" */'@/pages/order/detail.vue'),
        meta: {
            title: '我的订单',
        },
    },
    {
        path: '/log/:id',
        name: 'log',
        component: () => import(/* webpackChunkName: "log" */'@/pages/order/log.vue'),
        meta: {
            title: '物流进度',
        },
    },

];

export default order;
