import Vue from 'vue';
import VConsole from 'vconsole';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import { Lazyload } from 'vant';
import fetch from '@/utils/fetch';
import router from './router';
import store from './store';
import 'lib-flexible/flexible';
import './assets/styles/base';
import './assets/styles/variables';
import '@/directives';
import '@/filters';
import '../mock';

// eslint-disable-next-line no-new
if ((process.env.NODE_ENV === 'production' && process.env.VUE_APP_ISUAT) || process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-new
  new VConsole();
} else {
  Sentry.init({
    dsn: '',
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
  });
}

Vue.prototype.$fetch = fetch;
Vue.use(Lazyload);

function handleFontSize() {
  // 设置网页字体为默认大小
  window.WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: 0 });
  // 重写设置网页字体大小的事件
  window.WeixinJSBridge.on('menu:setfont', () => {
    window.WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: 0 });
  });
}
if (typeof WeixinJSBridge === 'object' && typeof window.WeixinJSBridge.invoke === 'function') {
  handleFontSize();
} else {
  document.addEventListener('WeixinJSBridgeReady', handleFontSize, false);
}

new Vue({
  router,
  store,
}).$mount('#app'); // 手动挂载
