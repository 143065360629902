/*
 * @Author: ajx
 * @Email: jianxin.an@medbanks.cn
 * @Date: 2020-11-10 15:53:00
 * @Last Modified by: zoe
 * @Last Modified time: 2022-03-31 15:28:52
 * @Description: Description
 * @Route: Route
 */
const storeSanheyuan = [
  {
    path: '/store-shy/:merchantCode',
    name: 'store-shy',
    component: () => import(/* webpackChunkName: "store" */ '@/pages/store_sanheyuan'),
    meta: {
      title: '',
    },
  },
  {
    path: '/search-store-shy',
    name: 'search-store-shy',
    component: () => import(/* webpackChunkName: "store_search" */ '@/pages/store_sanheyuan/search'),
    meta: {
      title: '',
    },
  },

];

export default storeSanheyuan;
