/*
 * @Author: ajx
 * @Email: jianxin.an@medbanks.cn
 * @Date: 2020-11-13 16:09:37
 * @Last Modified by: ajx
 * @Last Modified time: 2020-12-18 15:22:03
 * @Description: Description
 * @Route: Route
 */

const prescription = [
    {
        path: '/prescription-info/:from',
        name: 'prescription-info',
        component: () => import(/* webpackChunkName: "prescription" */ '@/pages/prescription'),
        meta: {
            title: '处方信息',
        },
    },
];

export default prescription;
